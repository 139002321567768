.hotel__card {
    display: flex !important;
    flex-direction: column;
    align-items: flex-start;

    border-radius: 16px;
    border: 1px solid var(--solid-black-10, #E5E5E5);
    background: var(--opacity-white-100, #FFF);

    overflow: hidden;
    width: 100%;
    height: 100%;

    .hotel__img {
        width: 100%;
        object-fit: cover;
        max-height: 200px;
    }

    .hotel__content {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 20px;

        padding: 20px;

        width: 100%;

        .content__header {
            display: flex !important;
            flex-direction: column;
            gap: 12px;

            width: 100% !important;

            .location {
                color: var(--solid-black-80, #333);

                margin-top: -8px;
            }

            .poppins__18:last-child {
                display: flex;
                align-items: center;
                gap: 12px;
            }
        }

        >hr {
            border: 1px solid #E5E5E5;

            width: 100%;
        }

        .card__btn{
            margin-top: auto;
        }

        .hotel__points {
            display: flex;
            flex-direction: column;
            gap: 8px;

            min-height: 108px;
            width: 100% !important;

            .point__li {
                display: flex;
                align-items: center;
            }
        }
    }
}

.hotel__pageCard{
    max-width: 100%;
}