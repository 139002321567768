.search__popup {

    opacity: 0;
    visibility: hidden;

    position: absolute;
    top: 130%;
    transition: .3s;
    left: 0;

    padding: 20px;
    height: fit-content;

    display: flex;
    flex-direction: column;
    gap: 28px;

    z-index: 8;

    /* Layout */

    border-radius: 16px;
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.15);
    background: var(--opacity-white-100, #FFF);

    .counter {
        display: flex;
        align-items: center;
        justify-content: space-between;

        width: 100%;

        .add,
        .nubmer,
        .substract {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 48px;
            height: 48px;

            border-radius: 50px;
            background: var(--solid-primary-5, #F6F8FB);
        }

        .nubmer {
            background-color: transparent;
            color: #000;
            text-align: center;
            font-family: Poppins;
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: 40px;
        }
    }

    .popup__text {
        display: flex;
        flex-direction: column;

        .poppins__18 {
            display: flex;
            align-items: center;
            gap: 12px;
        }
    }

    .btn__container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        width: 100%;

        .btn {
            color: var(--opacity-white-100);

            border: 0;
            background-color: var(--solid-primary-100);
        }
    }
}

.active__popup {
    visibility: visible;
    opacity: 1;
}

@media (max-width:786px) {
    .search__popup {
        padding: 14px;
        gap: 10px;
        max-width: 320px !important;
        min-width: 100% !important;
        max-height: 240px;
        overflow-y: scroll;
    }
}