.hotel__page {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 44px;
    width: 100%;

    .page__header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        width: 100%;

        .poppins__semiBold__16 {
            color: var(--solid-primary-100);

            cursor: pointer;
        }
    }

    .cards__container {
        gap: 20px;

        width: 100%;
    }

    .pagination__container {
        display: flex;
        align-items: center;
        justify-content: center;

        gap: 16px;


        .pagination__number {
            cursor: pointer;
        }

        .pagination__text {
            color: var(--solid-black-80, #333);
            text-align: center;
            cursor: pointer;
            /* Buttons/Medium */
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            /* 133.333% */
        }

        .low__opacity {
            color: var(--solid-black-20, #CCC);
            text-align: center;
            cursor: pointer;
            /* Buttons/Medium */
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            /* 133.333% */
        }

        .active__pagination {

            color: var(--opacity-white-100, #FFF);
            cursor: pointer;

            border-radius: 50px;
            background: var(--solid-primary-100, #4A6DA7);
        }

        .pagination__number {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            width: 56px;
            height: 56px;
        }

        .pagination__arrow {
            padding: 16px 24px;
            cursor: pointer;
        }
    }
}

.hotel__offer {
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../../../../public//images/home/startPlanning/starting__plan__back.png');
}