.feature__card {
    display: flex;
    padding: 24px;
    align-items: center;
    gap: 24px;

    width: 100%;
    height: 100%;

    border-radius: 8px;
    border: 1px solid var(--solid-black-10, #E5E5E5);
    background: var(--opacity-white-100, #FFF);

    .poppins__18 {
        display: inline;
        width: 100%;
        color: var(--solid-black-80, #333);
    }
}


@media (max-width:1406px) {
    .feature__card {
        height: 120px;
        gap: 12px;        
        
        padding: 16px;
        justify-content: center;
        flex-direction: column;

        .poppins__18 {
            text-align: center;

            max-width: 124px;
        }
    }
}


@media (max-width:796px) {
    .feature__card {
        height: 120px;
        min-width: 38%;
    }
}

@media (max-width:666px) {
    .feature__card {
        height: 120px;
        min-width: 47%;
    }
}