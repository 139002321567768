.main__input__field__div {
    display: flex;
    align-items: start;
    flex-direction: column;
    gap: 6px;
    width: 100%;
    position: relative;
    .input__field {
        display: flex;
        padding: 16px 20px;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        gap: 12px;
        width: 100%;
        border-radius: var(--2, 8px);
        border: 1px solid var(--black-20, #CCC);
        background: var(--white-100, #FFF);

        >input {
            width: 100%;
            border: 0;

            color: var(--solid-black-80, #333) !important;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            outline: none;

            &::placeholder {
                color: var(--black-40, #999);
            }
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
            transition: background-color 5000s ease-in-out 0s;
        }
    }

    .active__input__field {
        border: 2px solid var(--solid-primary-100);
    }

    .error__input__field {
        border: 1px solid #c53030 !important;
        >input {
            &::placeholder{ 
                color: #c53030 !important;
            }
        }
    }

    .error__of__input__field {
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 4px;
        color: #c53030 !important;
        font-size: 12px;
        text-align: left;
        bottom: -16px;
        text-transform: capitalize;
    }
}

@media (max-width: '768px') {
    .main__input__field__div{
        .input__field{
            padding: 10px 16px;
        }
    }
}