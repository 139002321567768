.contract__container {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 24px var(--6, 24px);
    align-self: stretch;

    .inner__container {
        display: flex;
        width: 100%;
        padding: var(--6, 24px);
        flex-direction: column;
        align-items: flex-start;
        gap: var(--6, 24px);
        flex: 1 0 0;

        border-radius: var(--4, 16px);
        border: 1px solid var(--solid-black-10, #E5E5E5);

        .container__li {
            display: flex;
            align-items: center;
            gap: var(--4, 16px);
            align-self: stretch;
            width: 100%;

            .li__bold {
                color: var(--solid-black-80, #333);
                font-feature-settings: 'clig' off, 'liga' off;
                font-family: Poppins;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 30px;

                max-width: 80%;
            }
        }
    }
}

@media (max-width:1440px) {
    .contract__container {
        .inner__container {
            .container__li {
                .li__bold {
                    font-size: 20px;
                }

                .poppins__18 {
                    font-size: 14px;
                }
            }
        }
    }
}

@media (max-width:1010px) {
    .contract__container {
        .inner__container {
            .container__li {
                .li__bold { 
                    font-size: 18px;
                }

                .poppins__18 {
                    font-size: 14px;
                }
            }
        }
    }
}