.inquiry__model {
    display: none;
    padding: var(--6, 24px) var(--8, 32px);
    flex-direction: column;
    align-items: flex-start;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
    max-width: 700px;
    width: 700px;
    gap: 24px;

    border-radius: var(--4, 16px);
    background: var(--opacity-white-100, #FFF);
    box-shadow: 0px 6px 60px 0px rgba(0, 0, 0, 0.07);

    .model__heading {
        color: var(--solid-black-80, #333);
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Poppins;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.56px;
    }

    .poppins__16 {
        max-width: 100%;
        text-align: center;
    }

    .input__field {
        display: flex;
        padding: var(--4, 16px) var(--5, 20px);
        justify-content: center;
        align-items: center;
        align-self: stretch;
        gap: 12px;

        border-radius: var(--2, 8px);
        border: 1px solid var(--black-20, #CCC);
        background: var(--white-100, #FFF);

        >textarea,
        >input {
            width: 100%;
            border: 0;
            color: var(--black-40, #999);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            outline: none;
            resize: none;

            &::placeholder {
                color: var(--black-40, #999);
            }
        }
    }

    .textarea__field {
        align-items: start;

        >textarea {
            min-height: 80px;
        }
    }
}

.inquiry__model__open {
    display: flex;
}

.loader__div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: absolute;
    height: 100%;
    z-index: 9999;
}

.model__back {
    inset: 0;
    position: fixed;

    background: rgba(0, 0, 0, 0.25);
    z-index: 9;
}

@media (max-width:768px) {
    .inquiry__model {
        padding: 16px 20px;
    }
}

@media (max-height: 750px) {
    .inquiry__model {
        transform-origin: center;
        transform: scale(.74) translateY(-66%) translateX(-70%);
    }
}


@media (max-height: 690px) {
    .inquiry__model {
        max-width: 560px;
        width: 540px;
        gap: 16px;
        transform: scale(.65) translateY(-76%) translateX(-70%);
    }

    .inquiry__model .input__group {
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 645px) {
    .inquiry__model {
        max-width: 360px;
        width: 340px;
        gap: 16px;
    }
}

@media (max-height: 620px) {
    .inquiry__model {
        max-width: 560px;
        width: 540px;
        gap: 16px;
    }

    .inquiry__model .input__group {
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 420px) {
    .inquiry__model {
        transform: scale(.6) translateY(-80%) translateX(-84%);
        max-width: 460px;
        width: 460px;
    }
}