.trip__planner {
    padding: 64px 52px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 48px;

    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../../../../../public/images/home/startPlanning/starting__plan__back.png');
    /* background-image: url('../../../../public/images/home/startPlanning/starting__plan__back.png'); */

    overflow: hidden;
    border-radius: 24px;

    width: 100%;

    .trip__heading {
        max-width: 670px;

        color: var(--opacity-white-100, #FFF);

        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Poppins;
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -1.92px;
    }

    .btn__container {
        display: flex;
        align-items: center;
        justify-content: center;

        gap: 24px;

        .btn {
            padding: 16px 32px;
            min-width: 240px;
            cursor: pointer;

            align-items: center;
            justify-content: center;

            border-radius: 100px;
            border: 1.5px solid var(--opacity-white-100, #FFF);
            background-color: transparent;
            font-size: 21px;
        }

        .btn:last-child {
            background-color: var(--opacity-white-100, #FFF);

            color: var(--solid-primary-100, #4A6DA7);
        }
    }
}

@media (max-width:1140px) {
    .trip__planner {
        padding: 52px 16px;
        gap: 32px;

        .trip__heading {
            max-width: 432px;
            font-size: 32px;
        }

        .btn__container {
            .btn {
                min-width: 186px;

                font-size: 16px;
                padding: 12px 32px;
            }
        }
    }
}

@media (max-width:560px) {
    .trip__planner {
        .trip__heading {
            max-width: 432px;
            font-size: 32px;
        }

        .btn__container {
            flex-direction: column;
            width: 100%;

            gap: 12px;

            .btn {
                min-width: 100%;

                font-size: 16px;
                padding: 12px 32px;
            }
        }
    }
}