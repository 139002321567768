@tailwind base;
@tailwind components;
@tailwind utilities;

/* ====== Google Fonts (Poppins) ====== */
/* ====== Google Fonts (Poppins) ====== */

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');




/* ====== Universal Reset ====== */
/* ====== Universal Reset ====== */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;

    font-family: 'Poppins', sans-serif;
}




/* ====== Body ====== */
/* ====== Body ====== */

html,
body,
#root {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}

#root {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 64px;
    padding: 0 6vw;
}



::selection {
    color: var(--opacity-white-100);
    background-color: var(--solid-primary-100, #4A6DA7);
}




/* ====== Root Varaibales ====== */
/* ====== Root Varaibales ====== */

:root {



    /* -__- _--_  Colors Variables -__- _--_ *\
    \* -__- _--_  Colors Variables -__- _--_ */

    /* Solid Black Color */

    --solid-black-100: #000000;
    --solid-black-80: #333333;
    --solid-black-60: #666666;
    --solid-black-40: #999999;
    --solid-black-20: #CCCCCC;
    --solid-black-10: #E5E5E5;
    --solid-black-5: #F2F2F2;

    /* Solid Black Color */

    --solid-blue-100: #0C4CB4;
    --solid-blue-80: #3D70C3;
    --solid-blue-60: #6D94D2;
    --solid-blue-40: #9EB7E1;
    --solid-blue-20: #CEDBF0;
    --solid-blue-10: #E7EDF7;
    --solid-blue-5: #F3F6FB;

    /* Solid Primary Color */

    --solid-primary-100: #4A6DA7;
    --solid-primary-80: #6E8AB9;
    --solid-primary-60: #92A7CA;
    --solid-primary-40: #B7C5DC;
    --solid-primary-20: #DBE2ED;
    --solid-primary-10: #EDF0F6;
    --solid-primary-5: #F6F8FB;

    /* Solid Orange Color */

    --solid-orange-100: #FF6B13;
    --solid-orange-80: #FF8942;
    --solid-orange-60: #FFA671;
    --solid-orange-40: #FFC4A1;
    --solid-orange-20: #FFE1D0;
    --solid-orange-10: #FFF0E7;
    --solid-orange-5: #FFF8F3;

    /* Opacity White Color */

    --opacity-white-100: #FFFFFF;
    --opacity-white-80: #FFFFFF80;
    --opacity-white-60: #FFFFFF60;
    --opacity-white-40: #FFFFFF40;
    --opacity-white-20: #FFFFFF20;
    --opacity-white-10: #FFFFFF10;
    --opacity-white-5: #FFFFFF05;
}



::-webkit-scrollbar{
    width: 6px;
    border-radius: 50px; 
    height: 6px;
}

::-webkit-scrollbar-thumb{
    background-color: var(--solid-primary-100, #4A6DA7);
    border-radius: 50px;
}


/* ====== Components ====== */
/* ====== Components ====== */

/* (Button Class btn) */

.btn {

    /* Layout */

    display: flex;
    padding: 8px 16px;
    align-items: center;
    gap: 8px;

    width: fit-content;

    /* Style */

    color: var(--opacity-white-100, #FFF);

    font-family: 'Poppins';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    border-radius: 100px;
    border: 1px solid var(--solid-orange-100, #FF6B13);
    background: var(--solid-orange-100, #FF6B13);
}

.sec__btn {

    /* Layout */

    display: flex;
    padding: 8px 24px;
    align-items: center;
    gap: 8px;

    width: fit-content;

    /* Style */

    color: var(--solid-orange-100, #FF6B13);

    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    border-radius: 100px;
    border: 1px solid var(--solid-orange-100, #FF6B13);
}

.link {
    color: var(--solid-black-80, #333);

    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.poppins__18 {
    color: var(--solid-black-60, #656565);

    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.poppins__16 {
    color: var(--solid-black-80, #333);

    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.poppins__14 {
    color: var(--solid-black-60, #656565);

    /* Poppins/14 */
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.25px;
}

.poppins__semiBold__16 {
    color: var(--solid-black-80, #333);

    /* Poppins / SemiBold / 16 */
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 125% */
}

.poppins__semiBold__24 {
    color: var(--solid-black-80, #333);

    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: -0.38px;
}

.small__btn {
    color: var(--solid-primary-100, #4A6DA7);

    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.big__btn {
    display: flex;
    padding: 16px 32px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    border-radius: 100px;
    background: var(--solid-orange-100, #FF6B13);

    /* shadow-base */
    box-shadow: 0px 1px 2px 0px rgba(31, 41, 55, 0.08);

    color: var(--opacity-white-100, #FFF);

    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.section__heading {
    color: var(--solid-black-80, #333);

    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -1.28px;
}

.snack__bar {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 20px;
    right: 20px;
    padding: 12px 20px;
    border-radius: 10px;
    background-color: #c53030;
    transition: .3s;
    color: white;
    box-shadow: 0 4px 10px rgba(31, 41, 55, 0.08);
    gap: 16px;
    z-index: 9999;

    .icon {
        cursor: pointer;
        color: white;
    }

    .poppins__18 {
        text-transform: capitalize;
        color: white;
        font-size: 24px;
        font-size: 500;
    }

    .poppins__14 {
        text-transform: capitalize;
        color: white;
        font-size: 16px;
        font-size: 500;
    }
}


@media (max-width:1440px) {
    body {
        gap: 32px;
    }
}

@media (max-width:1040px) {

    .small__btn {
        color: var(--solid-primary-100, #4A6DA7);
        text-align: center;

        /* Buttons/Small */
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 133.333% */
    }

    .poppins__18 {
        color: var(--solid-black-60, #656565);

        /* Poppins/14 */
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.25px;
    }

    .big__btn {
        display: flex;
        padding: 12px 24px;
        justify-content: center;
        align-items: center;
        gap: 8px;

        font-size: 15px;
    }

    .section {
        padding: 16px;
    }

    body {
        padding: 0;
    }

    .section__heading {
        font-size: 24px;
    }
}

@media (max-width:650px) {
    #root {
        gap: 24px;
        padding: 0 2vw;
    }
}

.referncer__slider .swiper-button-next,
.referncer__slider .swiper-button-prev {
    box-shadow: 0 4px 10px var(--solid-blue-20);
    top: auto !important;
    bottom: 10px !important;
}
