.header__section {
    width: 100%;
    margin-top: -40px;

    .hero__section {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        gap: 24px;

        padding: 0 0 44px 0;

        .hero__content {
            display: flex;
            flex-direction: column;
            gap: 54px;
            min-width: 44%;

            position: relative;

            margin-top: 60px;

            .hero__top {
                display: flex;
                flex-direction: column;
                gap: 16px;

                .heading {
                    color: var(--solid-primary-100, #4A6DA7);

                    font-feature-settings: 'clig' off, 'liga' off;
                    font-family: Poppins;
                    font-size: 56px;
                    font-style: normal;
                    font-weight: 600;
                    letter-spacing: -2.88px;

                    max-width: 500px;

                    >span {
                        color: var(--solid-black-80, #333);
                    }
                }

                .paragraph {
                    max-width: 460px;

                    color: var(--solid-black-60, #656565);

                    font-family: Poppins;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }

        .hero__img {
            min-width: 52%;
            display: flex;
            align-items: center;
            gap: 18px;

            .img:first-child {
                margin-bottom: 36px;
            }

            .img {
                overflow: hidden;
                max-width: 100%;

                height: auto;
                border-radius: 24px;

                >img {
                    width: 100%;
                }
            }
        }
    }
}

/* @media (max-width:1530px) {
    .header__section {
        .hero__section {
            .hero__img {
                .img {
                    width: 340px;
                }
            }
        }
    }
} */

@media (max-width:1354px) {
    .header__section {

        .hero__section {
            .hero__content {
                .hero__top {
                    gap: 8px;

                    .heading {
                        font-size: 50px;

                        line-height: 70px;
                        max-width: 100%;
                    }

                    .paragraph {
                        line-height: normal;
                        font-size: 18px;
                    }
                }

                .hero__search {
                    width: 956px;

                    left: 0%;
                    transform: scale(.9) translateX(-6%);
                }
            }
        }
    }
}

@media (max-width:1265px) {
    .header__section {
        .hero__section {
            .hero__content {
                .hero__search {
                    transform: scale(.8) translateX(-13%);
                }
            }
        }
    }
}

@media (max-width:1160px) {
    .header__section {

        .hero__section {
            align-items: center;
            flex-direction: column;

            .hero__img {
                display: none;
            }

            .hero__content {
                gap: 32px;
                min-width: 540px;
                margin-top: 0;

                .hero__top {
                    align-items: center;
                    gap: 8px;

                    .paragraph,
                    .heading {
                        text-align: center;
                    }

                    .heading {
                        font-size: 44px;
                        max-width: 336px;
                    }
                }

                .invisible {
                    display: none;
                }

                .hero__search {
                    box-shadow: none;
                    background-color: transparent;

                    display: grid;
                    grid-template-columns: repeat(3, 1fr);

                    width: auto;

                    position: static;
                    transform: scale(1);

                    .search__tab:first-child {
                        grid-column: 1/4;
                    }

                    .search__tab:nth-child(3) {
                        grid-column: 2/4;
                    }

                    .search__tab:nth-child(4) {
                        grid-column: 1/3;
                    }

                    .search__btn {
                        grid-column: 1/4;

                        >span {
                            >span {
                                display: inline;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width:680px) {
    .header__section {
        gap: 64px;
        display: flex;
        flex-direction: column;

        padding: 10px !important;

        .hero__section {
            padding: 0;

            .hero__content {

                margin-top: 32px;
                min-width: 100%;
                max-width: 100%;

                .hero__top {
                    .heading {
                        font-size: 36px;
                    }
                }

                .hero__search {
                    grid-template-columns: repeat(2, 1fr);
                }
            }
        }
    }
}