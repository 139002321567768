.hero__search {
    display: flex;
    gap: 6px;
    padding: 8px;

    width: 1010px;

    bottom: -60px;
    position: absolute;

    border-radius: 100px;
    background: var(--opacity-white-100, #FFF);
    box-shadow: 0px 10px 100px 0px rgba(0, 0, 0, 0.15);

    .search__tab {
        display: flex;
        padding: 10px 14px;
        gap: 8px;

        cursor: pointer;

        position: relative;

        transition: .3s;
        border: 1px solid transparent;
        border-radius: 50px;
        background: var(--solid-primary-5, #F6F8FB);

        .poppins__18 {
            color: var(--solid-black-60, #666666);

            text-wrap: nowrap;
        }
    }

    .search__tabActive,
    .search__tab:hover {
        border: 1px solid var(--solid-primary-100, #4A6DA7);
    }

    .search__btn {
        padding: 16px 32px;

        border-radius: 100px;
        background: var(--solid-orange-100, #FF6B13);

        box-shadow: 0px 1px 2px 0px rgba(31, 41, 55, 0.08);

        border: 0;

        .poppins__18 {
            color: var(--opacity-white-100);
        }

        >span {
            >span {
                display: none;
            }
        }
    }

    .calender__tab{
        z-index: 1;
        position: relative;
    }

    .search__btn:hover {
        border: 0;
    }
}

@media (max-width:1140px) {
    .nav__searchFilter{
        display: none !important;
    }
}