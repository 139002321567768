.footer__section {
    display: flex !important;
    align-items: center;
    flex-direction: column;

    gap: 42px;

    width: 100%;

    padding-bottom: 48px;

    .footer__header {
        display: flex !important;
        flex-direction: column;
        align-items: center;

        gap: 24px;

        max-width: 516px;

        .poppins__18 {
            text-align: center;
        }
    }

    .footer__links {
        display: flex !important;
        align-items: center;
        justify-content: space-between;

        flex-wrap: wrap;

        gap: 32px;
    }

    .footer__contacts {
        display: flex !important;
        align-items: center;

        width: 100%;

        .contact__div {
            height: 160px;
            width: 100%;

            display: flex !important;
            flex-direction: column;
            align-items: center !important;
            justify-content: center;
            gap: 16px;

            .poppins__18 {
                text-align: center;
                color: var(--solid-black-80, #333);
            }
        }

        .border__right {
            border-right: 1px solid var(--solid-black-10, #E5E5E5);
        }
    }

    .copy__right {
        .poppins__18 {
            color: var(--solid-black-60, #656565);
        }
    }

    .social__links {
        display: flex !important;
        align-items: center;
        gap: 24px;

        .icon {
            font-size: 36px;

            color: var(--solid-black-60);
        }

        .back__icon {
            display: flex !important;
            align-items: center;
            justify-content: center;

            font-size: 20px;

            border-radius: 50%;
            width: 32px;
            height: 32px;

            background-color: var(--solid-black-60);
            color: var(--opacity-white-100);
        }
    }
}


@media (max-width:1040px) {
    .footer__section {
        gap: 32px;

        .footer__links {
            gap: 4vw;

            align-items: center;
            justify-content: center;
        }
    }
}


@media (max-width:554px) {
    .footer__section {
        gap: 24px;

        .footer__links {
            gap: 16px;
        }

        .footer__contacts {
            display: grid !important;
            grid-template-columns: repeat(2, 1fr);
            gap: 16px;

            .contact__div {
                border-radius: 16px;
                border: 1px solid var(--solid-black-10, #E5E5E5);
            }

            .contact__div:last-child {
                grid-column: 1/3;
            }
        }
    }
}