.nav__bar {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    padding: 24px 0;

    .nav__logo {
        >img {
            width: 160px;
            height: 36px;
        }
    }

    .nav__links {
        display: flex;
        align-items: center;
        gap: 32px;

        .link {
            transition: .3s;
        }

        .link:hover {
            color: var(--solid-primary-100);

            >svg>path {
                transition: .3s;
            }

            >svg>path {
                stroke: var(--solid-primary-100);
            }
        }

        .active__link {
            color: var(--solid-blue-100, #0C4CB4);

            position: relative;
        }

        .active__link::after {
            content: '';
            position: absolute;

            left: 50%;
            transform: translateX(-50%);
            bottom: -4px;

            width: 30%;
            height: 2px;
            border-radius: 10px;
            background-color: var(--solid-blue-100);
        }

        .drop__link {
            display: flex;
            align-items: center;
            gap: 12px;

            position: relative;

            .drop__down {

                transition: .3s;
                opacity: 0;
                visibility: hidden;

                display: flex;
                width: 400px;
                padding: var(--2, 8px);
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: var(--2, 8px);

                top: 400%;
                position: absolute;

                left: 50%;
                transform: translateX(-50%);

                border-radius: var(--4, 16px);
                background: var(--opacity-white-100, #FFF);
                box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.15);

                .drop__link {
                    display: flex;
                    padding: 12px;
                    align-items: center;
                    align-self: stretch;
                    gap: 16px;

                    color: var(--solid-black-60, #333);

                    font-family: 'Poppins';
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;

                    text-transform: capitalize;

                    transition: .3s;
                }

                .drop__link.acitve,
                .drop__link:hover {
                    color: var(--solid-primary-100);
                    
                    border-radius: 10px;
                    background-color: var(--solid-primary-10);
                }
            }
        }

        .drop__link:hover{
            .drop__down {
                opacity: 1;
                visibility: visible;
                top: 120%;
            }
        }

        .active__link {
            cursor: pointer;

            >path {
                stroke: var(--solid-primary-100);
            }
        }
    }

    >.btn__container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 24px;
    }

    .menu__icon {
        width: 40px;
        height: 40px;

        display: none;
        align-items: center;
        justify-content: center;

        cursor: pointer;

        border: 1px solid var(--solid-black-20);
        border-radius: 50%;
    }

    /* Hotel Navbar Search Bar */



}

@media (max-width:1040px) {
    .nav__bar {
        padding: 24px 16px;

        .nav__links,
        .btn__container {
            display: none;
        }

        .menu__icon {
            display: flex;
        }
    }
}


@media (max-width:1320px) {
    .nav__bar {
        >.btn__container{
            gap: 12px;
        }
    }
}