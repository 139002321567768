.heroCalender__drop{
    position: absolute;
    top: 100%;
    left: 0px;
    z-index: 1;
}

.inputCalender__drop{
    position: absolute;
    top: 130%;
    left: 0px;
    z-index: 1;
}

@media (max-width:650px) {
    .heroCalender__drop,
    .inputCalender__drop{
        scale: .7;
        left: -64px;
    }

    .inputCalender__drop{
        top: 14px;
    }
}