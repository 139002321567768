.drop__down {

    transition: .3s;
    opacity: 0;
    visibility: hidden;

    top: 400%;
    display: flex;
    width: 400px;
    padding: var(--2, 8px);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--2, 8px);

    position: absolute;
    right: 0;

    z-index: 9;

    border-radius: var(--4, 16px);
    background: var(--opacity-white-100, #FFF);
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.15);

    .drop__link {
        display: flex;
        padding: 12px;
        align-items: center;
        align-self: stretch;
        gap: 16px;

        color: var(--solid-black-60, #333);

        font-family: 'Poppins';
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;

        text-transform: capitalize;

        transition: .3s;
    }

    .drop__link.acitve,
    .drop__link:hover {
        color: var(--solid-primary-100);

        border-radius: 10px;
        background-color: var(--solid-primary-10);
    }

    .drop__div {

        overflow: hidden;
        max-height: 48px;

        cursor: pointer;

        display: flex;
        padding: 12px;
        align-self: stretch;
        gap: 16px;

        color: var(--solid-black-60, #333);

        font-family: 'Poppins';
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;

        text-transform: capitalize;

        transition: .3s;

        border-radius: 10px;
        background-color: var(--solid-primary-10);

        >span{
            transition: .3s;
        }

        >span:hover{
            color: var(--solid-primary-100);
        }
    }
}

.drop__down.active {
    opacity: 1;
    visibility: visible;
    top: 300%;
}