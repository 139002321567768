.login__model {
    display: flex;
    padding: var(--6, 24px) var(--8, 32px) var(--3, 12px) var(--8, 32px);
    flex-direction: column;
    align-items: flex-start;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
    max-width: 450px;
    width: 450px;
    gap: 24px;

    border-radius: var(--4, 16px);
    background: var(--opacity-white-100, #FFF);
    box-shadow: 0px 6px 60px 0px rgba(0, 0, 0, 0.07);

    .model__heading {
        color: var(--solid-black-80, #333);
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Poppins;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.56px;
    }

    .poppins__16 {
        max-width: 280px;
        text-align: center;
    }

    .input__field {
        display: flex;
        padding: var(--4, 16px) var(--5, 20px);
        justify-content: center;
        align-items: center;
        align-self: stretch;
        gap: 12px;

        border-radius: var(--2, 8px);
        border: 1px solid var(--black-20, #CCC);
        background: var(--white-100, #FFF);

        >input {
            width: 100%;
            border: 0;

            color: var(--black-40, #999);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            outline: none;

            &::placeholder {
                color: var(--black-40, #999);
            }
        }
    }
}

.model__back {
    inset: 0;
    position: fixed;

    background: rgba(0, 0, 0, 0.25);
    z-index: 9;
}

@media (max-width:645px) {
    .login__model {
        max-width: 340px;
        width: 340px;
        gap: 16px;
    }
}