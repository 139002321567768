/* 
.cookies-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #f0f0f0;
    padding: 10px;
    border-top: 1px solid #ccc;
  }
  
  .cookie-preferences {
    margin-top: 10px;
  }
  
  .cookie-preferences label {
    display: block;
    margin-bottom: 5px;
  }
  
  .cookies-banner button {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .cookie-label {
    font-weight: bold;
  }
  
  .cookie-description {
    color: #555;
    font-size: 0.9em;
  }

  .preferences-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    z-index: 999;
  }
  
  .banner-content {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
  }
  
  .options {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
  }
  
  .options label {
    margin: 0 10px;
  }
  
  button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  } */


.cookies-banner .cookie-preferences {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  margin-bottom: 24px;
}

.cookies-banner .cookie-preferences label {
  display: flex;
  align-items: center;
  gap: 8px;
}

.cookies-banner .cookie-preferences input {
  cursor: pointer;
}

.cookies-banner .cookie-preferences span {
  color: #666;
}

.cookies-banner .cookie-preferences .cookie-label {
  font-weight: bold;
  color: #4A6DA7;
}

.cookies-banner .cookie-preferences .cookie-description {
  font-size: 12px;
  color: #666;
}

.cookies-banner .buttons {
  display: flex;
  justify-content: center;
  gap: 16px;
}

.cookies-banner .buttons button {
  background-color: #4A6DA7;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.cookies-banner .buttons button:hover {
  background-color: #365b8a;
}

.link-button {
  background: none;
  border: none;
  color: #4A6DA7;
  text-decoration: underline;
  cursor: pointer;
}

.checkbox-container {
  width: 90px;
  height: 45px;
}

.checkbox-circle {
  width: 50px;
  height: 38.6px;
}

.switch-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
}

.switch {
  width: 66px;
  height: 40px;
  border-radius: 20px;
  background-color: #BFC6CC;
  /* Default background color */
  position: relative;
}

.switch.active {
  background-color: #4A6DA7;
  /* Active background color */
}

.switch-knob {
  width: 30px;
  height: 30px;
  border-radius: 60%;
  background-color: #FFF;
  /* Knob color */
  position: absolute;
  top: 5px;
  left: 5px;
  transition: transform 0.3s ease;
}

.switch.active .switch-knob {
  transform: translateX(26px);
  /* Move knob to the right when active */
}

.switch-label {
  margin-left: 10px;
  font-size: 12px;
  color: #666666;
  /* Default label color */
  transition: color 0.3s ease;
}

.switch-label.active {
  color: #4A6DA7;
  /* Active label color */
}

.switch-name {
  margin-bottom: 8px;
  /* Adjust as needed */
}

.switch.disabled {
  opacity: 0.7;
  cursor: default;
}

.switch-name {
  cursor: default;
}

span,
p {
  text-align: left;
}

.cookies-banner {
  >img {
    width: 130px;
    height: 25px;
  }
}
