.tab__bar {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 0 10vw;
    padding-bottom: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--6, 24px);
    overflow-y: scroll;
    z-index: 2;
    position: fixed;
    background: var(--opacity-white-100, #FFF);

    .tabBar__header {
        display: flex;
        padding: var(--4, 16px) 0px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        >img {
            max-width: 108px;
        }
    }

    .bar__links {
        display: flex;
        flex-direction: column;
        align-self: stretch;
        gap: 12px;
        width: 100%;
        height: 100%;
        justify-content: flex-start;
    }

    .link {
        color: var(--solid-black-60, #333);
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        transition: .3s;
        padding: 16px;
        border-radius: 10px;
    }

    .drop__div {
        overflow: hidden;
        height: 40px;
        cursor: pointer;
        display: flex;
        align-self: stretch;
        gap: 16px;
        border-radius: 10px;
        padding: 16px;
        color: var(--solid-black-60, #333);
        font-family: 'Poppins';
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        text-transform: capitalize;
        transition: .3s;
        border-radius: 10px;

        >span {
            transition: .3s;
        }

        >span:hover {
            color: var(--solid-primary-100);
        }
    }

    .openDrop__div {
        background-color: #F6F8FB;
        height: fit-content;
    }

    .link.active,
    .link:hover {
        background-color: #F6F8FB;
        color: var(--solid-primary-100, #4A6DA7);
    }

    .contact__details {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;


        .contact__div {
            display: flex;
            padding: 16px 12px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 16px;
            flex: 1 0 0;
            align-self: stretch;
            border: 1px solid var(--solid-black-80);
            border-radius: 10px;
            .link {
                text-align: center;
                font-size: 14px;
            }
        }
    }
}

@media (max-width:650px) {
    .tab__bar {
        padding: 0 4vw;
        padding-bottom: 2vw;
        .link {
            font-size: 16px;
        }

        .contact__details {
            display: grid;
            grid-template-columns: repeat(2, 1fr);

            .contact__div:last-child {
                grid-column: 1/3;
            }
        }
    }
}