.feature__section {
    display: flex;
    align-items: center;
    gap: 24px;
}

@media (max-width:796px) {
    .feature__section {
        overflow: scroll;
    }
}