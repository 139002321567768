.hotel__cards {
    display: flex;
    flex-direction: column;
    gap: 44px;

    width: 100%;
    
    align-items: center;

    .cards__container {
        gap: 20px;

        width: 100%;
    }
}

@media (max-width:1040px) {
    .hotel__cards{
        .cards__container{
            display: flex;
            align-items: center;
            overflow-x: scroll;

            .hotel__card{
                min-width: 300px;
            }
        }
    }
}